<template>
  <div class="wrap">
    <van-tabs v-model:active="active" class="common-tabs">
      <van-tab title="优选商城">
        <div class="main">
          <div class="box nopadding"  v-for="item in yxsc_data" :key="item.id">
            <div class="thumb" @click="toDetail(item)">
              <img :src="item.goods_main_img" class="thumb-img" alt="">
            </div>
            <div class="info">
              <div class="title">
                <span class="text">{{ item.goods_name }}</span>
                <span class="tip">{{ item.guige }}</span>
              </div>
              <div class="desc">
                <div class="labels">
                  <div class="label">
                    <img src="@/assets/img/zzjf.png" alt="" class="icon">
                    <span>{{ item.shoujia }}</span>
                  </div>
                </div>
                <div class="sum">
                  <van-stepper v-model="item.prePayNum" />
                </div>
                <div class="btn">
                  <VanButton type="primary" block size="small" @click="handleClickItem(item)">兑换</VanButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="我的订单">
        <div class="main">
          <div class="box" v-for="item in my_yxsc_order" :key="item.id">
            <div class="top">
              <div class="date">{{ item.input_time }}</div>
              <div class="order">NO.{{ getNo(item) }}</div>
            </div>
            <div class="box-head">
              <div class="title">
                <span class="text">{{ item.goods_data.goods_name }}</span>
                <span class="tip">{{ item.goods_data.guige }}</span>
              </div>
              <div class="number">x {{ item.num }}</div>
            </div>
            <div class="address">
              <div class="name">
                <span class="tag">自提门店</span>
                <span>{{ item.store_data.store_name }}</span>
              </div>
              <div class="detail">{{ item.store_data.mddz }}</div>
            </div>
            <div class="box-btn">
              <VanButton type="primary" v-if="item.status == '0'" block size="normal" @click="handleConfirm(item)">确认提货</VanButton>
              <a class="btn-text" v-if="item.status == '1'">已提货</a>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// @ is an alias to /src

import { showConfirmDialog, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
export default {
  name: 'Fwtc',
  data() {
    return {
      active: 0
    }
  },
  created() {
    this.get_yxsc_data();
    this.get_yxsc_order({
      sjhm: this.$route.query.sjhm
    })
  },
  computed: {
    ...mapState(['yxsc_data', 'my_yxsc_order']),
  },
  methods: {
    ...mapActions(['get_yxsc_data', 'get_yxsc_order', 'tihuo']),
    toDetail(item) {
      this.$router.push({
        path: '/yxsc-detail',
        query: {
          sjhm: this.$route.query.sjhm,
          sfbs: this.$route.query?.sfbs || '',
          id: item.id,
        }
      })
    },
    handleClickItem(item) {
      // console.log(item.prePayNum);
      this.$router.push({
        path: '/ddqr',
        query: {
          sjhm: this.$route.query.sjhm,
          sfbs: this.$route.query?.sfbs || '',
          id: item.id,
          num: item.prePayNum || 1
        }
      })
    },
    getNo(item) {
      return dayjs(item.input_time).format('YYYYMMDDhhmmss') + item.id;
    },
    handleConfirm(item) {
      showConfirmDialog({
        title: '提示',
        message: `是否确认商品[${item.goods_data.goods_name}]已提货?`,
        confirmButtonText: '确定'
      }).then(async res => {
        // console.log(res);
        let { data: result } = await this.tihuo({
          sjhm: this.$route.query.sjhm,
          order_id: item.id
        })
        if (result?.success) {
          showNotify({
            type: 'success',
            message: result.msg || '提货成功'
          })
          this.get_yxsc_order({
            sjhm: this.$route.query.sjhm
          })
        } else {
          showNotify({
            type: 'danger',
            message: result.msg || '网络错误'
          })
        }
      })
    }
  },

}
</script>
<style lang="less" scoped>
.wrap {
  .main {
    // margin-top: 24px;

    .box {
      margin: 12px;
      padding: 12px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;

      &.nopadding {
        padding: 0;

        .info {
          padding: 0 12px 12px 12px;
        }
      }

      .thumb {
        overflow: hidden;
        margin-bottom: 12px;

        .thumb-img {
          vertical-align: top;
          width: 100%;
          height: auto;
        }
      }

      .top {
        display: flex;
        margin-bottom: 6px;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #999;
      }

      .box-btn {
        margin-top: 12px;
        .btn-text {
          font-size:14px;
          display: block;
          text-align: center;
          color:cornflowerblue;
        }
      }

      .box-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .title {
          flex: 1;
        }

        .number {
          color: #3fb6c6;
          font-size: 16px;
        }
      }

      .address {
        .name {
          font-size: 20px;
          color: #333;
          margin-bottom: 6px;
          line-height: 32px;

          .tag {
            display: inline-block;
            padding: 0 12px;
            height: 32px;
            line-height: 32px;
            border-radius: 3px;
            background: #3fb6c6;
            color: #fff;
            margin-right: 12px;
            font-size: 16px;
          }
        }

        .detail {
          font-size: 16px;
          color: #666;
        }
      }

      .title {
        font-size: 20px;
        color: #333;

        .tip {
          font-size: 14px;
          color: #999;
          margin-left: 12px;
        }
      }

      .desc {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .labels {
          display: flex;
          align-items: center;
          flex: 1;

          .label {
            display: flex;
            align-items: flex-end;
            margin-right: 24px;

            .icon {
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }

            span {
              font-size: 18px;
              color: #3fb6c6;
            }
          }
        }

        .btn {
          margin-left: 12px;
          width: 80px;
        }

      }
    }
  }
}
</style>
